import React, { useState, useRef, useEffect } from "react"
import Heading from "atomic-core/components/patterns/01-atoms/texts/heading/Heading"
import SpeakerCard from "./SpeakerCard"
import SpeakerDescription from "./SpeakerDescription"

import SEO from "../seo"

const Speakers = () => {
  const [openState, setOpenState] = useState(false)
  const [descriptionWidth, setDescriptionWidth] = useState(0)
  const [dynamicStyles, setDynamicStyles] = useState({})
  const [data, setData] = useState({ pageTitle: "" })
  const speakersContainer = useRef(null)

  const defineDescriptionWidth = () => {
    if (speakersContainer.current) {
      if (window.innerWidth > 1440) {
        setDescriptionWidth(speakersContainer.current.offsetWidth / 2 - 30)
      } else if (window.innerWidth > 1199) {
        setDescriptionWidth(
          (speakersContainer.current.offsetWidth / 5) * 3 - 30
        )
      } else if (window.innerWidth > 1023) {
        setDescriptionWidth(speakersContainer.current.offsetWidth / 2 - 30)
      } else if (window.innerWidth > 350) {
        setDescriptionWidth(
          (speakersContainer.current.offsetWidth / 3) * 3 - 30
        )
      }
    }
  }

  const closeAllCards = () => {
    defineDescriptionWidth()

    if (speakersContainer.current) {
      speakersContainer.current.querySelectorAll(".active").forEach(card => {
        card.classList.remove("active")
      })
    }
    setOpenState(false)
  }

  const handleCardClick = e => {
    if (!e.currentTarget.classList.contains("active")) {
      closeAllCards()
      e.currentTarget.classList.add("active")
      setOpenState(true)
      const offset =
        speakersContainer.current.offsetWidth -
        15 -
        e.currentTarget.offsetLeft -
        descriptionWidth
      offset < 0
        ? setDynamicStyles({
            width: `${descriptionWidth}px`,
            marginLeft: `${offset}px`,
          })
        : setDynamicStyles({ width: `${descriptionWidth}px` })
    } else {
      closeAllCards()
    }
  }
  const handleCardFocus = e => {
    // if another card is open and user is traversing through keyboard then close the open card
    if(openState && !e.target.classList.contains("active")) {
      closeAllCards()
    }
  }

  const handleCloseClick = e => {
    e.preventDefault()
    closeAllCards()
  }

  const getSpeakersData = async () => {
    let response = await fetch(
      `${process.env.API_URL}/${process.env.EVENT_ID}/speakers`
    )
    response = await response.json()

    if (response && response.status === 200 && response.result) {
      setData(response.result)
    }
  }

  const closeSpeakerDescriptionBox = e => {
    const clickedWithinSpeakerDescriptionBox = e.target.closest(
      ".speaker-description"
    );
    if (openState && !clickedWithinSpeakerDescriptionBox) {
      closeAllCards();
    }
  };

  useEffect(() => {
    getSpeakersData();
    defineDescriptionWidth();
    window.addEventListener("resize", closeAllCards);
    document.addEventListener("click", closeSpeakerDescriptionBox);

    return () => {
      window.removeEventListener("resize", closeAllCards);
      document.removeEventListener("click", closeSpeakerDescriptionBox);
    };
  }, [openState]);

  return (
    <>
      <SEO title="Speakers" />

      <div className="page speakers-block">
        <Heading
          headingType={"h2"}
          globalModifiers={
            "d-xs-none d-sm-block text-size-h3 font-primary-bold mb-35"
          }
        >
          {data.pageTitle}
        </Heading>

        <div
          className={`speakers ${openState ? "active" : ""}`}
          ref={speakersContainer}
        >
          {data.speakers &&
            data.speakers.map((speaker, idx) => {
              return (
                <div className={`speaker`} key={idx}>
                  <SpeakerCard
                    name={speaker.speaker_name}
                    company={speaker.speaker_company}
                    imgSrc={speaker.speaker_image_path}
                    onClick={handleCardClick}
                    onFocus={handleCardFocus}
                  />
                  <SpeakerDescription
                    styles={dynamicStyles}
                    onClose={handleCloseClick}
                    name={speaker.speaker_name}
                    description={speaker.speaker_desc}
                  />
                </div>
              )
            })}
          {/* {!data.speakers && <div>
            <p>There was some issue with our end please refresh or contact event organizer</p>
          </div>
            } */}
        </div>
      </div>
    </>
  )
}

export default Speakers
